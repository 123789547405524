import { template as template_e6485ad0f48144fa83ee169f7338adfb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e6485ad0f48144fa83ee169f7338adfb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
