import { template as template_e5eb4abac433472db091ba104a18c90f } from "@ember/template-compiler";
const FKLabel = template_e5eb4abac433472db091ba104a18c90f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
