import { template as template_e55322b473ce4d60acf4f6d887fa2235 } from "@ember/template-compiler";
const WelcomeHeader = template_e55322b473ce4d60acf4f6d887fa2235(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
