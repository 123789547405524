import { template as template_7984ae25fd304043ae5fa27654ef583c } from "@ember/template-compiler";
const FKControlMenuContainer = template_7984ae25fd304043ae5fa27654ef583c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
